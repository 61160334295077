import FooterBGImage from "../Assets/Homepics/HomeBgPic.jpg";
import GardenResLogo from "../Assets/Homepics/Garden-Residences-logo.png";
import { FaFacebookSquare, FaInstagram, FaTiktok } from 'react-icons/fa';

export default function GardenResFooter() {

    return (
        <footer className="sm:pb-[650px] relative lg:w-full lg:h-[10px] lg:-mb-[320px] md:h-3 sm:h-[180px] bg-no-repeat bg-center bg-gray-500 bg-blend-multiply" style={{ backgroundImage: `url(${FooterBGImage})` }}>
            <div className="px-[1200px] relative lg:w-[600px] lg:h-[150px] bg-no-repeat bg-center">
                <div className="mx-auto w-full max-w-screen-xl p-4 py-7 lg:py-8">
                    <div className="2xl:mt-[20px] md:-mt-[20px] h-full w-full -ml-[850px] mt-[30px] flex justify-center">
                        <a href="/" className="">
                            <img src={GardenResLogo} className="2xl:h-[80px] 2xl:-mt-[50px] 2xl:ml-[500px] :-mt-[40px] lg:h-[50px] sm:h-[50px] md:h-[100px] md:ml-[120px] lg:-ml-[120px] xl:h-[70px] xl:ml-[10px] sm:pr-[240px] me-[320px] -mb-7" alt="GardenResLogo" />
                        </a>
                    </div>
                    <div className="md:mb-[50px] 2xl:mb-[510px] md:flex md:justify-center">
                        <div className="pt-[30px] grid grid-cols-7 gap-7 sm:gap-[170px] lg:grid-cols-6 sm:grid-cols-1">
                            <div className="2xl:-ml-[420px] md:-ml-[1070px] md:text-[17px] sm:text-[13px] sm:-ml-[1210px] lg:-ml-[750px] xl:-ml-[680px] xl:text-[14px] lg:mt-[20px] lg:text-[10px] text-white">
                                <p>
                                    Stay with us and let your dreams take flight—experience <br /> a perfect getaway where comfort meets luxury, and every <br /> moment is crafted to make your stay unforgettable. <br /> Your ideal escape begins here
                                </p>
                            </div>
                            <div>
                                <h2 className="2xl:ml-[5px] lg:-ml-[550px] lg:-mt-[50px] mb-6 lg:text-sm font-semibold text-white uppercase sm:-mt-[130px] sm:-ml-[1200px] sm:text-[12px] md:-ml-[880px] xl:-ml-[320px]">CONTACT</h2>
                                <ul className="2xl:ml-[5px] md:-ml-[940px] lg:-ml-[590px] lg:text-[10px] xl:text-[13px] text-white dark:text-gray-400 font-medium sm:-mt-[20px] sm:-ml-[1200px] sm:text-[12px] lg:mt-[12px] xl:-ml-[385px]">
                                    <p>
                                        Viber, Whatsapp, Telegram,
                                        Mobile
                                        <p className="lg:mt-[11px] md:ml-[45px] 2xl:-ml-[1px]">
                                            09202098674
                                        </p>
                                    </p>
                                </ul>
                            </div>
                            <div>
                                <h2 className="2xl:ml-[100px] 2xl:mb-[40px] lg:-ml-[530px] lg:-mt-[50px] lg:mb-6 lg:text-sm font-semibold text-white uppercase dark:text-white whitespace-nowrap sm:ml-[1200px] sm:text-[12px] sm:-mt-[220px] md:-ml-[880px] xl:-ml-[300px]">FOLLOW US</h2>
                                <ul className="lg:ml-[20px] text-white lg:text-lg dark:text-gray-400 font-medium sm:-ml-[1200px] sm:text-[12px] sm:-mb-[30px]">
                                    <li className="lg:mb-4 ">
                                        <a href="https://facebook.com/profile.php?id=100077859550266" target="_blank" rel="noopener noreferrer">
                                            <FaFacebookSquare className='2xl:ml-[100px] text-blue-500 lg:h-5 lg:w-7 md:ml-[350px] lg:-ml-[525px] lg:-mt-[20px] mr-2 sm:h-[12px] xl:-ml-[300px] transition-transform transform hover:scale-110' />
                                            <a href="https://facebook.com/profile.php?id=100077859550266" className="2xl:ml-[100px] md:ml-[331px] lg:-ml-[538px] lg:text-sm xl:-ml-[315px] flex items-center text-white hover:underline" target="_blank" rel="noopener noreferrer">Facebook</a>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/gardenresidencespampanga/" target="_blank" rel="noopener noreferrer">
                                            <FaInstagram className='2xl:ml-[100px] text-pink-400 lg:h-5 lg:-ml-[525px] lg:-mt-[10px] lg:w-7 mr-2 sm:h-[12px] md:ml-[350px] xl:-ml-[300px] transition-transform transform hover:scale-110' />
                                            <a href="https://www.instagram.com/gardenresidencespampanga/" className="2xl:ml-[100px] md:ml-[331px] lg:-ml-[538px] lg:text-sm xl:-ml-[315px] flex items-center text-white hover:underline" target="_blank" rel="noopener noreferrer">Instagram</a>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-lg font-semibold text-white uppercase dark:text-white whitespace-nowrap"></h2>
                                <ul className="lg:-ml-[20px] lg:-mt-[80px] pt-[27px] lg:text-lg text-white dark:text-gray-400 font-medium sm:-ml-[1200px] sm:text-[12px] sm:-mt-[362px]">
                                    <li className="mb-4">
                                        <a href="https://tiktok.com/@admngardenres_2022?lang=en" target="_blank" rel="noopener noreferrer">
                                            <FaTiktok className='2xl:-ml-[30px] 2xl:mt-[110px] text-white lg:h-5 lg:-ml-[655px] lg:mt-[95px] lg:w-7 mr-2 xl:-ml-[428px] sm:h-[11px] md:ml-[350px] transition-transform transform hover:scale-110' />
                                            <a href="https://tiktok.com/@admngardenres_2022?lang=en" className="2xl:-ml-[30px] lg:-ml-[665px] md:ml-[337px] xl:-ml-[435px] lg:text-sm xl:text-sm flex items-center text-white hover:underline" target="_blank" rel="noopener noreferrer">Tiktok</a>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="2xl:ml-[80px] lg:mb-6 lg:text-sm lg:-mt-[50px] font-semibold lg:-ml-[670px] text-white uppercase dark:text-white whitespace-nowrap sm:-ml-[1200px] sm:text-[12px] sm:-mt-[427px] md:-ml-[862px] xl:-ml-[400px]">EMAIL</h2>
                                <ul className="2xl:ml-[20px] text-white dark:text-gray-400 font-medium lg:text-sm lg:-ml-[730px] lg:-mt-[10px] sm:-ml-[1200px] sm:text-[12px] md:-ml-[921px] xl:-ml-[450px]">
                                    <p>
                                        admngardenres@gmail.com
                                    </p>
                                </ul>
                            </div>
                            <div>
                                <h2 className="2xl:ml-[250px] 2xl:-mt-[50px] lg:-ml-[1235px] lg:mb-6 lg:text-sm lg:mt-[70px] xl:-ml-[1010px] font-semibold text-white uppercase dark:text-white whitespace-nowrap sm:-ml-[1200px] sm:text-[12px] sm:-mt-[540px] md:-ml-[870px]">LOCATION</h2>
                                <ul className="2xl:ml-[1610px] lg:ml-[120px] lg:text-xs lg:mt-[35px] xl:ml-[350px] text-white dark:text-gray-400 font-medium whitespace-nowrap sm:-ml-[1200px] sm:text-[12px] sm:-mt-[2px] md:-ml-[945px]">
                                    <p className="sm:px-[1px] lg:-ml-[1430px] lg:-mt-[30px]">
                                        Garden Residences, Villa Esperanza Subd.,
                                        <br />Purification St., Amsic, Angeles City
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="2xl:-ml-[1200px] 2xl:-mt-[450px] xl:-ml-[1200px] xl:mt-[70px] md:-mt-[517px] md:-ml-[950px] lg:-ml-[1200px] lg:mt-[87px] sm:-ml-[1165px] sm:-mt-[420px] text-xs text-white whitespace-nowrap md:-mt-[px]">
                        <p className="">&copy; {new Date().getFullYear()} Garden Residence. All rights reserved.</p>

                    </div >
                    <div className="2xl:ml-[1150px] xl:ml-[30px] lg:-ml-[320px] lg:-mt-[18px] lg:-mb-[200px] sm:-ml-[1165px] sm:-mt-[30px] text-xs text-white whitespace-nowrap md:-ml-[885px] md:-mt-[2px]">
                        <p className="">Developed by Mab</p>

                    </div>
                </div>
            </div>
            <div className="md:-mt-[70px] sm:pb-[100px] sm:-mt-[80px] lg:mt-[100px] bg-black pb-[70px] bg-blend-multiply opacity-30">

            </div>

        </footer>
    )
}