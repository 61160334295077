import { FaHouseDamage } from "react-icons/fa";
import { AiTwotoneSecurityScan } from "react-icons/ai";
import { IoBed } from "react-icons/io5";
import { GiBarbecue } from "react-icons/gi";
import { LiaSwimmerSolid } from "react-icons/lia";

export default function BedroomPagesIcons() {
    return (
        <div className="2xl:mt-[50px] 2xl:ml-[180px] xl:-ml-[370px] xl:gap-[270px] xl:text-[14px] sm:grid-cols-2 sm:grid sm:text-[8px] sm:-mt-[130px] sm:ml-[50px] flex flex-col items-center md:grid md:grid-cols-5 md:pt-32 py-20 md:px-[200px] md:gap-[150px] md:-ml-[110px] sm:gap-[80px] xl:px-[500px] px-10">
            <div>
                <IoBed className=" text-black text-5xl sm:text-[30px] xl:text-[50px]" />
                <p className="font-bold text-black -ml-[85px] sm:-ml-[45px] whitespace-nowrap">FULLY FURNISHED BEDROOM</p>
            </div>
            <div>
                <FaHouseDamage className="text-black text-5xl sm:text-[30px] xl:text-[50px]"/>
                <p className="font-bold text-black -ml-[40px] whitespace-nowrap md:-ml-[20px]">GENERATOR SET</p>
            </div>
            <div>
                <AiTwotoneSecurityScan className="text-black text-5xl sm:text-[30px] xl:text-[50px]" />
                <p className="font-bold text-black -ml-[30px] whitespace-nowrap md:-ml-[20px]">24/7 SECURITY</p>
            </div>
            <div>
                <GiBarbecue className="text-black text-5xl sm:text-[30px] xl:text-[50px]" />
                <p className="font-bold text-black -ml-[120px] whitespace-nowrap md:-ml-[70px]">BARBEQUE AREA, LOBBY AND GARDEN</p>
            </div>
            <div>
                <LiaSwimmerSolid className="text-black text-5xl sm:text-[30px] xl:text-[50px]" />
                <p className="font-bold text-black -ml-[40px] whitespace-nowrap md:-ml-[20px]">SWIMMING POOL</p>
            </div>
        </div>
    )
}