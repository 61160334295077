import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navigation';
import About from './Components/About';
import HomeGal1 from './Components/HomeGalery1';
import StandardStudioPage from './Pages/StandardStudio';
import OneBedRoomUnitPage from './Pages/OneBedroomUnit';
import DeluxeStudioPage from './Pages/DeluxeStudio';
import RoomsPage from './Pages/RoomsPage'
import { Suspense } from 'react'


function App() {
  return (
   < BrowserRouter >
      <Routes>
        <Route path="/" element={<Navbar/>}/>
        <Route index element={<Home/>}/>
        <Route path="/About" element={<About/>}/>
        <Route index element={<HomeGal1/>}/>
        <Route path="/standard-studio" element={<StandardStudioPage/>}/>
        <Route path="/1-bedroom-unit" element={<OneBedRoomUnitPage/>}/>
        <Route path="/deluxe-studio" element={<DeluxeStudioPage/>}/>
        <Route path="rooms" element ={<RoomsPage/>}/>

        

      </Routes>
   </BrowserRouter>
  )
}

export default App;
