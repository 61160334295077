import { Headline } from "../Interface/GardenPageHeadline"

export default function PageBanner(props: Headline) {
    const { Heading, SubHeading } = props
    return (
        <div className="px-4  max-w-screen-xl py-24 -mt-24 md:py-36">

            <div className="px-4 max-w-screen-xl md:py-36">
                <h1 className='xl:text-3xl md:-ml-[100px] sm:mt-[100px] sm:-mb-[10px] sm:ml-[62px] sm:text-lg tracking-widest mb-4 text-sm font-thin px-2 md:px-48 leading-none text-shadow  text-white lg:text-xl revealTop'>Garden Residence</h1>
                <h1 className="md:-ml-[100px] sm:text-2xl sm:-mb-[50px] sm:ml-[8px] sm:tracking-[10px] sm:whitespace-nowrap tracking-widest mb-4 text-2xl font-thin leading-none text-white md:text-5xl lg:text-7xl fade px-2 md:px-48">{Heading}</h1>
                <p className="mb-8 text-sm font-normal text-gray-50 md:text-sm  fade  xl:text-lg md:max-w-4xl xl:max-w-7xl px-2 md:px-48">{SubHeading}</p>
            </div>
        </div>
    )
}