import StandardStudio1 from "../../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio1.jpg"
import StandardStudio2 from "../../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg"
import StandardStudio3 from "../../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio3.jpg"
import StandardStudio4 from "../../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio4.jpg"
import StandardStudio5 from "../../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio5.jpg"

export const StandardStudioCarouselDetails = [
{ name: "Standard1", image: StandardStudio1 },
{ name: "Standard2", image: StandardStudio2 },
{ name: "Standard3", image: StandardStudio3 },
{ name: "Standard4", image: StandardStudio4 },
{ name: "Standard5", image: StandardStudio5 },
]