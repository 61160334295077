import { GardenDetailsProps } from "../Interface/GardenDetails";

export default function GardenDetails(props: GardenDetailsProps) {
    const { data } = props;
    const {  Heading, Details1, Details2, From, To, Location } = data
    return (
        <div className="xl:-ml-[300px] revealLeft">
            <div className="flex">
            
                <h1 className="sm:text-2xl text-2xl font-thin mb-10 ml-4 leading-10 mt-4 text-lagrand-greenlight  md:text-5xl">{Heading}</h1>

            </div>

            <p className="xl:mx-[70px] xl:text-xl xl:-ml-[50px] mb-2 text-sm md:text-3xl font-thin tracking-wide">{Details1}<br /></p>
            <p className="mb-2 text-sm md:text-3xl font-thin tracking-wide"><br />{Details2}</p>


            {From !== "" && <><h1 className="mb-2 text-sm md:text-3xl font-thin tracking-wide">Operating Hours</h1>
                <p className="mb-8 text-sm font-normal text-gray-600 md:max-w-2xl md:text-[17px]">Open daily from {From} to {To}</p>
            </>}

            
        </div>
    )
}