import { useEffect, useState } from "react";
import homepic1 from "../Assets/Homepics/Homepic1.jpg";
import homepic2 from "../Assets/Homepics/Homepic2.jpg";
import homepic3 from "../Assets/Homepics/Homepic3.jpg";
import HomepicSS1 from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio1.jpg";
import HomepicSS2 from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg";
import HomepicSS3 from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio3.jpg";
import HomepicSS4 from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio4.jpg";
import HomepicSS5 from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio5.jpg";
import HomepicDS1 from "../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio1.jpg";


export const HomeGalDescriptions = [
    {name: "Home-Like Environment", description:"A perfect place where it feels like home. A home-like environment that will satisfy your stay with us.", image: homepic1},
    {name: "Cozy Rooms", description:"A complete room that has a soft bed with fresh linens, a basic kitchen setup, a spacious bathroom, and Smart TV for entertainment", image: homepic2},
    {name: "Convenient Service", description:"To complete your stay, we also offer a lot of services for your convenience. Room Cleaning and Laundry Service!", image: homepic3}
]

export const HomeGalDescriptions2 = [
    {name: "Standard Studio", description: "Discover comfort and style in our Standard Studio, featuring sleek furnishings, cozy bedding, and modern amenities. Enjoy bright, airy spaces with expansive city views, perfect for both business and leisure stays.", image: HomepicSS1},
    {name: "Deluxe Studio", description: "Indulge in luxury with our Deluxe Studio, offering high-end finishes, a gourmet kitchenette, and a plush living area. Floor-to-ceiling windows showcase stunning views, while premium amenities ensure a refined and relaxing experience.", image: HomepicDS1},
    {name: "1 Bedroom Unit", description: "Enjoy spacious comfort in our 1 Bedroom Unit, featuring a separate bedroom, a modern kitchen, and a cozy living area. With stylish decor and ample natural light, it’s perfect for both extended stays and relaxing getaways.", image: HomepicDS1}
]

