import SSRoomPic from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio2.jpg"
import OneBURoomPic from "../Assets/Homepics/HomePicsStandardStudio/HomepicStandardStudio5.jpg"

export default function OtherRoomsDS() {
    return (
        <section className="sm:-mt-[300px]">
            <div className="xl:mt-[80px] flex justify-center pb-4 -mt-24">
                <h1 className="tracking-widest font-bold text-3xl">OTHER ROOMS</h1>
            </div>
            <div className="xl:ml-[220px] xl:mr-[100px] md:grid-cols-2 sm:grid sm:grid-cols-1 flex justify-center items-center pb-7">
                <div className="2xl:ml-[550px] mr-2 px-5 max-w-lg p-7 bg-white shadow-lg rounded-lg">
                    <div className="flex justify-center">
                        <figure className="max-w-lg ">
                            <img className="h-auto max-w-full rounded-lg transform transition-transform duration-300 ease-in-out hover:scale-105" src={SSRoomPic} alt="image description" />
                            <figcaption className="mt-2 text-2xl text-center text-black dark:text-gray-400">Standard Studio</figcaption>
                        </figure>
                    </div>
                    <div className="pb-7">
                        <p className="text-md text-black mx-4 text-center">
                            Discover comfort and style in our Standard Studio, featuring sleek furnishings, cozy bedding, and modern amenities. Enjoy bright, airy spaces with expansive city views, perfect for both business and leisure stays.
                        </p>
                    </div>
                </div>
                <div className="max-w-lg p-6 bg-white shadow-lg rounded-lg">
                    <div className="flex justify-center">
                        <figure className="max-w-lg ">
                            <img className="h-auto max-w-full rounded-lg transform transition-transform duration-300 ease-in-out hover:scale-105" src={OneBURoomPic} alt="image description" />
                            <figcaption className="mt-2 text-2xl text-center text-black dark:text-gray-400">1 Bedroom Unit</figcaption>
                        </figure>
                    </div>
                    <div className="pb-7">
                        <p className="text-md text-black mx-4 text-center">
                        Enjoy spacious comfort in our 1 Bedroom Unit, featuring a separate bedroom, a modern kitchen, and a cozy living area. With stylish decor and ample natural light, it’s perfect for both extended stays and relaxing getaways.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}