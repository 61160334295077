import DeluxeStudio1 from "../../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio1.jpg"
import DeluxeStudio2 from "../../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio2.jpg"
import DeluxeStudio3 from "../../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio3.jpg"
import DeluxeStudio4 from "../../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio4.jpg"
import DeluxeStudio5 from "../../Assets/Homepics/HomePicsDeluxeStudio/HomePicDeluxeStudio5.jpg"

export const DeluxeStudioCarouselDetails = [
{ name: "Deluxe1", image: DeluxeStudio1 },
{ name: "Deluxe2", image: DeluxeStudio2 },
{ name: "Deluxe3", image: DeluxeStudio3 },
{ name: "Deluxe4", image: DeluxeStudio4 },
{ name: "Deluxe5", image: DeluxeStudio5 },
]