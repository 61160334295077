'use-client';
import { Carousel } from "flowbite-react";
import { CarouselProps } from "../Interface/GardenCarousel";
import { GardeUseFullScreenImage } from "../Hooks/GardenUseFullScreenImage";

export default function GardenCarouselDesktop(props: CarouselProps) {
    const { data } = props
    const { fullscreenImage, setFullscreenImage, handleImageClick } = GardeUseFullScreenImage()
    return (
        <div>
            <div className="xl:-ml-[50px] xl:-mt-[80px] xl:-mx-[250px] revealRight h-[470px] hidden overflow-hidden rounded-3xl  md:flex">
                <Carousel leftControl="" className="" rightControl=""
                >
                    {data.map((item) => (
                        <img src={item.image} alt={item.name} />
                    ))}

                </Carousel>
           
            </div>
            <div className="md:hidden grid gap-2 grid-cols-3">
                {data.map((item) => (
                    <img src={item.image} alt={item.name} onClick={() => handleImageClick(item.image)} />
                ))}
            </div>
            {fullscreenImage && (
                <div id="fullscreen-image" className="fixed top-0 left-0 z-50 w-screen h-screen  bg-black flex justify-center items-center">

                    <img src={fullscreenImage} alt="Fullscreen" onClick={() => setFullscreenImage(null)} className="max-h-full max-w-full cursor-pointer" />
                </div>)}
        </div>

    )
}