import { useState } from "react";

export const GardeUseFullScreenImage = ()=>{
    const [fullscreenImage, setFullscreenImage] = useState(null);

    const handleImageClick = (imageSrc:any) => {
        setFullscreenImage(imageSrc);
        // Request fullscreen mode
        const img = document.getElementById('fullscreen-image');
        if (img) {
            if (img.requestFullscreen) {
                img.requestFullscreen();
            } else if (img.requestFullscreen) {
                img.requestFullscreen();
            } else if (img.requestFullscreen) {
                img.requestFullscreen();    
            }
        }
    };
    return{fullscreenImage,setFullscreenImage,handleImageClick}
}